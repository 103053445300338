import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import TextIntro from '@/components/TextIntro'
import { pageFadeInOut } from '@/modules/animationVariants'

import { SideNavigationLink } from '@/components/SideNavigationLink'
import { useContentInProp } from '@/hooks/useContentInProp'
import { RightOnSmaller } from '@/layouts/styled'

const TourPage: React.FC = () => {
  const { t } = useTranslation()

  const content = {
    title: t('careers.tour_title'),
    text: t('careers.tour_body'),
  }
  const inProp = useContentInProp()

  return (
    <>
      <RightOnSmaller>
        {inProp && (
          <SideNavigationLink
            variant="light"
            position="right"
            delay={1.1}
            time={1}
            url="/careers/tour/tour-video"
            text="learn more"
          />
        )}
      </RightOnSmaller>
      <Page
        content={
          <PageWrapper>
            <TextIntro titleText={content.title} paragraphText={content.text} />
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#5a7d69',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
        }}
      />
    </>
  )
}

export default TourPage
